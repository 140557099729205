import Vue from 'vue'
import OnDemand from '~~/utils/helpers/onDemand'

/* global VueSocialSharing */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/vue-social-sharing.min.js?v=3.0.5'
  })

  inject('social', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          Vue.use(VueSocialSharing.default)
          resolve()
          if (callback) callback()
        })
      })
  })
}

import OnDemand from '~~/utils/helpers/onDemand'

export default (ctx, inject) => {
  const loader = new OnDemand({
    script: [
      'https://service.thenaturalsapphirecompany.com/cdn/jquery-3.6.0.min.js',
      'https://about.emeralds.com/wp-content/plugins/easy-accordion-free/public/assets/js/collapse.min.js',
      'https://service.thenaturalsapphirecompany.com/cdn/accordion.min.js'
    ],
    css: [
      'https://about.emeralds.com/wp-content/plugins/easy-accordion-free/public/assets/css/ea-style.css'
    ]
  })

  inject('accordion', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve()
          if (callback) callback()
        })
      })
  })
}

import Vue from 'vue'
import OnDemand from '~~/utils/helpers/onDemand'

/* global VueClipboard */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/vue-clipboard.min.js'
  })

  inject('clipboard', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          Vue.use(VueClipboard)
          resolve()
          if (callback) callback()
        })
      })
  })
}

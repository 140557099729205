import Vue from 'vue'
import OnDemand from '~~/utils/helpers/onDemand'

const apiKey = {
  ruby: 'G-XYCXVMTQ40',
  emerald: 'G-MPHM2K3VNK',
  gemstone: 'G-XWZEXSR87Z',
  sapphire: 'G-WZKCBJ55TL'
}

/* global VueGtag */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/vue-gtag.umd.min.js?v=1.11.0'
  })

  inject('vueGTag', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          Vue.use(VueGtag, {
            config: {
              id: process.env.isDev
                ? 'UA-153554273-1'
                : apiKey[process.env.siteName]
            },
            params: {
              anonymize_ip: true, // anonymize IP
              send_page_view: false, // might be necessary to avoid duplicated page track on page reload
              linker: {
                domains: getGtagDomains()
              }
            }
          })
          resolve()
          if (callback) callback()
        })
      })
  })

  function getGtagDomains() {
    if (!process.env.isDev) return [process.env.prodDomain]
    return ['localhost:3000', process.env.devDomain]
  }
}

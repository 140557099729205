export default ($axios) => ({
  // old feature coming from NEC
  fetchAvailableDesigns: async (id) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/setting/renderings/${id}`
    )
    return data
  },

  fetchFinalOptions: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/setting/finished`,
      query
    )
    return data
  },

  fetchFinalPrice: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/setting/price`,
      query
    )
    return data
  },

  inquiryCustomItem: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/preview/inquiry`,
      query
    )
    return data
  },

  itemsPrice: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/setting/itemsprice`,
      query
    )
    return data
  },

  fetchCustomSettingsDesignsFilters: async (id) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/preview/filters/setting/${id}`
    )
    return data
  },

  fetchCustomStoneDesignsFilters: async (id) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/preview/filters/stone/${id}`
    )
    return data
  },

  fetchCustomDesigns: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/preview`,
      query
    )
    return data
  },

  isStoneMatch: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/setting/match`,
      query
    )
    return data
  }
})

import OnDemand from '~~/utils/helpers/onDemand'

const apiKey = {
  ruby: {
    prod: '28f38b129544978e67adc55146db4e35af29c774',
    staging: 'cb5307babc81f51cc45e6f2925a9f784f96b586b'
  },
  emerald: {
    prod: '8153afba06ac25c83869a1c1bab31e52ffaf5d54',
    staging: '18b0cc8ae7361876225b874115708a9885ecc8e5'
  },
  gemstone: {
    prod: '3dbe208602ecbc60c521e79c1bc0d00c0f750246',
    staging: 'fb7c23a20485d5054abd3b3b5cad3a7e0b4f83c6'
  },
  sapphire: {
    prod: '109f80267f813f8099f32de34bad02d64149f585',
    staging: '3b4a97b8c2afa1cedc868b40f50ed4f0292c2712'
  }
}

const env = process.env.isDev ? 'staging' : 'prod'

const script = process.env.isDev
  ? 'https://cdn-sandbox.ca-dev.co/web/v2/chargeafter.min.js'
  : 'https://cdn.chargeafter.com/web/v2/chargeafter.min.js'

/* global ChargeAfter */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script: `${script}?t=${Date.now()}`
  })

  inject('chargeAfter', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          ChargeAfter.init({
            apiKey: apiKey[process.env.siteName][env]
          })
          resolve()
          if (callback) callback()
        })
      })
  })
}

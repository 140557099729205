import OnDemand from '~~/utils/helpers/onDemand'

export default (ctx, inject) => {
  window._rejoiner = window._rejoiner || []
  window._rejoiner.push(['setAccount', process.env.rejoinerSiteID])
  window._rejoiner.push(['setDomain', process.env.rejoinerProductionSiteID])
  window._rejoiner.push([
    'setExcludeFieldName',
    ['cc-name', 'cc-number', 'cc-exp-month', 'cc-exp-year', 'cc-csc']
  ])
  window._rejoiner.push(['trackPageView', true])

  const loader = new OnDemand({
    script: '//cdn.rejoiner.com/js/v4/rj2.lib.js'
  })

  inject('rejoiner', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve()
          if (callback) callback()
        })
      }),
    push: (params) => {
      if (window?._rejoiner) {
        window._rejoiner.push(params)
        return
      }
      console.log('Rejoiner is not initialized')
    }
  })
}

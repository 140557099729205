import OnDemand from '~~/utils/helpers/onDemand'
export default (ctx, inject) => {
  const loader = new OnDemand({
    script: '/js/microsoftClarity.js',
    head: true
  })

  inject('microsoftClarity', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve()
          if (callback) callback()
        })
      })
  })
}

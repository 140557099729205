export default ($axios) => ({
  // fetchList: async () => {
  //   const data = await $axios.$get(`public/${process.env.siteName}/account/comparelist`)
  //   return data
  // },

  /**
   * Fetch full items by guid from short item
   * @param query
   * @returns {Promise<any>}
   */
  fetchListItems: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/metadata-detail`,
      query
    )
    return data
  },

  mergeList: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/comparelist/merge`,
      query
    )
    return data
  },

  removeFromList: async (query) => {
    const data = await $axios.$delete(
      `public/${process.env.siteName}/account/comparelist`,
      query
    )
    return data
  }
})

import OnDemand from '~~/utils/helpers/onDemand'

/* global tippy */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script: [
      'https://service.thenaturalsapphirecompany.com/cdn/popper.min.js?v=2.11.2',
      'https://service.thenaturalsapphirecompany.com/cdn/tippy-bundle.umd.min.js?v=6.3.7'
    ],
    css: ['https://service.thenaturalsapphirecompany.com/cdn/light.css?v=6.3.7']
  })

  inject('tippy', {
    insert: (el, options) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve(tippy(el, options))
        })
      })
  })
}

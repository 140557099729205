export default ($axios) => ({
  itemsPrice: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/gemstone/itemsprice`,
      query
    )
    return data
  },

  video360Details: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/gemstone/videos360`,
      query
    )
    return data
  },

  submitAskAboutForm: async (form) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/cart/ask-designer`,
      form
    )
    return data
  },

  fetchJewelryDetails: async (id) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/jewelry/stock/${id}`
    )
    return data
  },

  fetchStoneDetails: async (id) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/gemstone/${id}`
    )
    return data
  },

  fetchPairsDetails: async (id) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/gemstone/pairs/${id}`
    )
    return data
  },

  fetchPlainBandsDetails: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/band/price/plain`,
      query
    )
    return data
  },

  fetchBandsDetails: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/band/price/gemstone`,
      query
    )
    return data
  },

  fetchSettingsDetails: async ({ id, metalTypeCode }) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/setting/metadata/${id}/${metalTypeCode}`
    )
    return data
  },

  fetchSideStonesOptions: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/band/sidestone-options`,
      query
    )
    return data
  },

  fetchRingSizes: async (type) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/${type}/ring-sizes`
    )
    return data
  },

  getProductLink: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/product/link`,
      query
    )
    return data
  },

  shareProduct: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/product/share`,
      query
    )
    return data
  }
})

import OnDemand from '~~/utils/helpers/onDemand'

export default (ctx, inject) => {
  const loader = new OnDemand({
    script: 'https://apis.google.com/js/platform.js'
  })

  inject('googleSurvey', {
    insert: (params, callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          window.renderOptIn = function () {
            window.gapi.load('surveyoptin', function () {
              window.gapi.surveyoptin.render(params)
            })
          }
          if (callback) callback()
          resolve()
        })
      })
  })
}

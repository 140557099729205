import OnDemand from '~~/utils/helpers/onDemand'

/* global Extend */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://sdk.helloextend.com/extend-sdk-client/v1/extend-sdk-client.min.js'
  })

  inject('extendSDK', {
    insert(options = {}, callback) {
      return new Promise((resolve, reject) => {
        loader.load(() => {
          const defaultConfig = {
            storeId: process.env.extendStoreId,
            environment: process.env.isDev ? 'demo' : 'production'
          }

          Extend.config({
            ...defaultConfig,
            ...options
          })
          resolve()
          if (callback) callback()
        })
      })
    },
    insertButtons(options = {}, callback) {
      if (!window.Extend) {
        // Extend not initialized
        return
      }
      const elementId = options.ref || '#extend-offer'
      const element = document.getElementById(elementId.replace('#', ''))

      // if element exists and Extend not inserted
      if (element && !element.childNodes.length) {
        Extend.buttons.render(elementId, options)
        setTimeout(() => {
          if (callback) callback()
        }, 2000)
      }
    },
    async insertSimpleButtons(options = {}) {
      if (!window.Extend) {
        await this.insert()
        this.insertSimpleButtons(options)
        return
      }
      const elementId = options.ref || '#extend-offer'
      const element = document.getElementById(elementId.replace('#', ''))

      // if element exists and Extend not inserted
      if (element && !element.childNodes.length) {
        Extend.buttons.renderSimpleOffer(elementId, options)
      }
    },
    async showModal(options = {}) {
      if (!window.Extend) {
        await this.insert()
        this.showModal(options)
        return
      }
      Extend.modal.open(options)
    },
    getPlan(options = {}) {
      if (!window.Extend) {
        // Extend not initialized
        return null
      }

      const elementId = options.ref || '#extend-offer'
      if (document.getElementById(elementId.replace('#', ''))) {
        /** get the component instance rendered previously */
        const component = Extend.buttons.instance(elementId)

        if (!component) return null

        /** get the users plan selection */
        return component.getPlanSelection()
      }
      return null
    },
    updateButtons(options = {}) {
      if (!window.Extend) {
        // Extend not initialized
        return null
      }

      const elementId = options.ref || '#extend-offer'

      if (!elementId || !options.extendReferenceId) return

      if (document.getElementById(elementId.replace('#', ''))) {
        /** get the component instance rendered previously */
        const component = Extend.buttons.instance(elementId)

        if (!component) return null

        /** get the users plan selection */
        return component.setActiveProduct(options.extendReferenceId)
      }
      return null
    }
  })
}

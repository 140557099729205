<template lang="pug">
.loading-overlay(v-if="showOverlay", ref="overlay")
</template>

<script>
import { mapActions, mapState } from 'vuex'

export default {
  name: 'LoadingOverlayNuxtComponent',

  data: () => ({
    showOverlay: false
  }),

  computed: {
    ...mapState({
      loading: (state) => state.loading,
      nextRoute: (state) => state.navigation.nextRoute
    })
  },

  created() {
    if (!process.client) return
    this.$nextTick(() => {
      if (this.loading) this.start()
    })
  },

  methods: {
    ...mapActions({
      globalLoading: 'globalLoading',
      clearNextRoute: 'navigation/clearNextRoute'
    }),

    disableScrolling() {
      const html = document.getElementsByTagName('html')[0]
      if (html) html.classList.add('stop-scrolling')
    },

    enableScrolling() {
      const html = document.getElementsByTagName('html')[0]
      if (html) html.classList.remove('stop-scrolling')
    },

    start() {
      this.showOverlay = true
      this.globalLoading(this.showOverlay)
      this.$nextTick(() => {
        this.addBlockEvents()
      })
    },

    finish() {
      this.$nextTick(() => {
        this.globalLoading(false)
        this.clearNextRoute()
      })
      setTimeout(() => {
        this.removeBlockEvents()
        if (this.$refs.overlay?.showElements) this.$refs.overlay.showElements()
        this.showOverlay = false
      }, 0)
    },

    addBlockEvents() {
      if (this.$refs.overlay?.$el)
        this.$refs.overlay.$el.addEventListener(
          'click',
          this.blockEvents,
          false
        )
    },

    removeBlockEvents() {
      if (this.$refs.overlay?.$el)
        this.$refs.overlay.$el.removeEventListener('click', this.blockEvents)
    },

    blockEvents(e) {
      e.preventDefault()
      e.stopPropagation()
      return false
    }
  }
}
</script>

<style lang="sass" scoped>
.loading-overlay
  z-index: 9999 !important
  position: fixed
  box-sizing: border-box
  left: 0
  top: 0
  width: 100%
  height: 100vh
  background: rgba(0, 0, 0, 0.2)
  opacity: 1
//pointer-events: none
</style>

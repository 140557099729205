import OnDemand from '~~/utils/helpers/onDemand'

export default (ctx, inject) => {
  const loader = new OnDemand({
    script: [
      'https://www.recaptcha.net/recaptcha/api.js?onload=onloadCallback&render=explicit'
    ]
  })

  inject('recaptcha', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve()
          if (callback) callback()
        })
      })
  })
}

import OnDemand from '~~/utils/helpers/onDemand'

export default (ctx, inject) => {
  const loader = new OnDemand({
    script: 'https://apis.google.com/js/platform.js'
  })

  inject('googleBadge', {
    insert: (params, callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          window.renderBadge = function () {
            const ratingBadgeContainer = document.createElement('div')
            document.body.appendChild(ratingBadgeContainer)
            window.gapi.load('ratingbadge', function () {
              window.gapi.ratingbadge.render(ratingBadgeContainer, {
                // REQUIRED
                merchant_id: process.env.merchantId,
                // OPTIONAL
                position: 'BOTTOM_LEFT'
              })
            })
          }
          window.renderBadge()
          if (callback) callback()
          resolve()
        })
      })
  })
}

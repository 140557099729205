export default ($axios) => ({
  // Address book START
  fetchAddressesList: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/account/address-list`
    )
    return data
  },

  saveAddress: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/address`,
      query
    )
    return data
  },

  deleteAddress: async (query) => {
    const data = await $axios.$delete(
      `public/${process.env.siteName}/account/address`,
      query
    )
    return data
  },

  setDefaultAddress: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/default-address`,
      query
    )
    return data
  },
  // Address book END

  // Notifications START
  fetchNotificationsList: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/account/notification`
    )
    return data
  },

  saveNotificationsList: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/notification`,
      query
    )
    return data
  },
  // Notifications END

  fetchRecommendations: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/account/recommended`
    )
    return data
  },

  deleteRecommendation: async (query) => {
    const data = await $axios.$delete(
      `public/${process.env.siteName}/account/recommended`,
      query
    )
    return data
  },

  fetchProfile: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/account/profile`
    )
    return data
  },

  fetchPreviewSubmissions: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/account/preview-submissions`
    )
    return data
  },

  /**
   * Fetch compare/favorite/cart short items list
   * @returns {Promise<any>}
   */
  fetchLists: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/account/metadata`
    )
    return data
  },

  updateProfile: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/profile`,
      query
    )
    return data
  },

  shippingAddressUpdate: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/address/shipping`,
      query
    )
    return data
  },

  billingAddressUpdate: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/address/billing`,
      query
    )
    return data
  },

  changePassword: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/password`,
      query
    )
    return data
  },

  requestResetPassword: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/forgot-password`,
      query
    )
    return data
  },

  resetPassword: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/reset-password`,
      query
    )
    return data
  },

  setPassword: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/create-password`,
      query
    )
    return data
  }
})

import OnDemand from '~~/utils/helpers/onDemand'

/* global Swiper */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/swiper-bundle.min.js?v=6.8.4'
    // css: [
    //   'https://service.thenaturalsapphirecompany.com/cdn/swiper-bundle.min.css?v=6.8.4'
    // ]
  })
  // CSS added on all pages in basePage component
  inject('swiper', {
    insert: (el, options, callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve(new Swiper(el, options))
          if (callback) callback()
        })
      })
  })
}

import Vue from 'vue'
import OnDemand from '~~/utils/helpers/onDemand'

/* global VueDatePick */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/vueDatePick.min.js?v=1.4.1',
    css: [
      'https://service.thenaturalsapphirecompany.com/cdn/vueDatePick.min.css?v=1.4.1'
    ]
  })

  inject('datePicker', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          Vue.component('VueDatePick', VueDatePick)
          resolve()
          if (callback) callback()
        })
      })
  })
}

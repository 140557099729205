import OnDemand from '~~/utils/helpers/onDemand'

/* global noUiSlider */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/nouislider.min.js?v=15.5.1',
    css: [
      'https://service.thenaturalsapphirecompany.com/cdn/nouislider.min.css?v=15.5.1'
    ]
  })

  inject('nouislider', {
    insert: (el, options, callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve(noUiSlider.create(el, options))
          if (callback) callback()
        })
      })
  })
}

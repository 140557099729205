import OnDemand from '~~/utils/helpers/onDemand'

/* global Popper */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/popper.min.js?v=2.11.2'
  })

  inject('popper', {
    insert: (el, tooltip, options, callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve(Popper.createPopper(el, tooltip, options))
          if (callback) callback()
        })
      })
  })
}

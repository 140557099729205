import OnDemand from '~~/utils/helpers/onDemand'

/* global Tabulator */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script: [
      'https://service.thenaturalsapphirecompany.com/cdn/tabulator.min.js?v=5.0.7'
    ],
    css: [
      'https://service.thenaturalsapphirecompany.com/cdn/tabulator_bulma.min.css?v=5.0.7'
    ]
  })

  inject('tabulator', {
    insert: (el, options, callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve(new Tabulator(el, options))
          if (callback) callback(Tabulator)
        })
      })
  })
}

import debounce from 'lodash/debounce'
import OnDemand from '~~/utils/helpers/onDemand'

/* global LazyLoad */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/lazyload.min.js?v=17.3.1'
  })

  inject('lazyload', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          window.lazy = new LazyLoad({
            // use_native: true,
            cancel_on_exit: false,
            elements_selector: '.lazy',
            class_loaded: 'lazy-loaded',
            unobserve_entered: true,
            callback_enter: executeLazyFunction,
            threshold: 300
          })

          window.lazyUpdate = debounce(function () {
            if (window.lazy) window.lazy.update()
          }, 50)

          window.lazy.update()

          resolve(window.lazy)
          if (callback) callback()
        })
      })
  })

  window.lazyFunctions = {
    loaded(element) {
      // it's better to use specific attribute instead of specific class
      // class list can be overwritten by other scripts
      element.setAttribute('lazy-status', 'entered')
    }
  }

  function executeLazyFunction(element) {
    const lazyFunctionName = element.getAttribute('data-lazy-function')
    const lazyFunction = window.lazyFunctions[lazyFunctionName]
    if (!lazyFunction) return
    lazyFunction(element)
  }
}

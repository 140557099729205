import OnDemand from '~~/utils/helpers/onDemand'

/* global Hls */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/hls.min.js?v=1.1.5'
  })

  inject('hls', {
    insert(el, source, options, callback, fallback) {
      loader.load(() => {
        if (Hls && Hls.isSupported()) {
          const player = new Hls(options)
          player.loadSource(source)
          player.attachMedia(el)
          player.on(Hls.Events.BUFFER_CREATED, () => {
            if (options.autoplay)
              el.play().then(() => {
                if (callback) callback()
              })
          })
          return player
        } else if (fallback) fallback()
      })
    }
  })
}

import OnDemand from '~~/utils/helpers/onDemand'

export default (ctx, inject) => {
  try {
    const loader = new OnDemand({
      script: `https://maps.googleapis.com/maps/api/js?key=${process.env.googleMapKey}&language=en&libraries=places`
    })

    inject('googleMaps', {
      insert: (callback) =>
        new Promise((resolve, reject) => {
          loader.load(() => {
            resolve()
            if (callback) callback()
          })
        })
    })
  } catch (e) {
    console.log(e)
  }
}

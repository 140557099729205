import Vue from 'vue'
import OnDemand from '~~/utils/helpers/onDemand'

/* global vuedraggable */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script: [
      'https://service.thenaturalsapphirecompany.com/cdn/Sortable.min.js?v=1.13.0',
      'https://service.thenaturalsapphirecompany.com/cdn/vuedraggable.umd.min.js?v=2.24.3'
    ]
  })

  inject('draggable', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          Vue.component('Draggable', vuedraggable)
          resolve()
          if (callback) callback()
        })
      })
  })
}

import OnDemand from '~~/utils/helpers/onDemand'

/* global agGrid */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script: [
      'https://service.thenaturalsapphirecompany.com/cdn/ag-grid-community.min.noStyle.js?v=26.2.1'
    ],
    css: [
      'https://service.thenaturalsapphirecompany.com/cdn/ag-grid.css?v=26.2.1',
      'https://service.thenaturalsapphirecompany.com/cdn/ag-theme-alpine.css?v=26.2.1'
    ]
  })

  inject('agGrid', {
    insert: (eGridDiv, gridOptions, callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve(new agGrid.Grid(eGridDiv, gridOptions))
          if (callback) callback()
        })
      })
  })
}

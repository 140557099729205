import OnDemand from '~~/utils/helpers/onDemand'

/* global Swal */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/sweetalert2.all.min.js?v=10.16.7',
    css: [
      'https://service.thenaturalsapphirecompany.com/cdn/sweetalert2.min.css?v=10.16.7'
    ]
  })

  inject('sweetalert', {
    insert: (options, mixin, callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          if (mixin) {
            const toast = Swal.mixin(mixin)
            toast
              .fire(options)
              .then((response) => {
                resolve(response)
              })
              .catch((error) => {
                reject(error)
              })
            if (callback) callback()
          } else {
            Swal.fire(options)
              .then((response) => {
                resolve(response)
              })
              .catch((error) => {
                reject(error)
              })
            if (callback) callback()
          }
        })
      })
  })
}

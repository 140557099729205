export default ($axios) => ({
  fetchJewelryFilters: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/jewelry/filters`,
      query
    )
    return data
  },

  fetchSettingsFilters: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/setting/filters`,
      query
    )
    return data
  },

  fetchPlainBandsFilters: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/band/plain/filters`,
      query
    )
    return data
  },

  fetchBandsFilters: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/band/filters`,
      query
    )
    return data
  },

  fetchStonesFilters: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/gemstone/filters`,
      query
    )
    return data
  },

  fetchPairsFilters: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/gemstone/pairs/filters`,
      query
    )
    return data
  }
})

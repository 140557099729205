export default ($axios) => ({
  /**
   * Fetch full items by guid from short item
   * @param query
   * @returns {Promise<any>}
   */
  fetchListItems: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/metadata-detail`,
      query
    )
    return data
  },

  mergeList: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/shoppingcart/merge`,
      query
    )
    return data
  },

  removeFromList: async (query) => {
    const data = await $axios.$delete(
      `public/${process.env.siteName}/account/shoppingcart`,
      query
    )
    return data
  },

  fetchCartMetadata: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/cart/metadata`
    )
    return data
  },

  taxes: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/cart/taxes`,
      query
    )
    return data
  },

  makeOrder: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/cart/order`,
      query
    )
    return data
  },

  makeAuthOrder: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/cart/auth/order`,
      query
    )
    return data
  },

  askDesigner: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/cart/ask-designer`,
      query
    )
    return data
  },

  requestPreview: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/preview/create`,
      query
    )
    return data
  },

  sendShareCart: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/anonymous-data/share-cart`,
      query
    )
    return data
  }
})

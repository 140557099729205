import app from './app'
import cart from './cart'
import filters from './filters'
import favorites from './favorites'
import compareList from './compareList'
import listings from './listings'
import details from './details'
import customItem from './customItem'
import bestsellers from './bestsellers'
import localPrices from './localPrices'
import auth from './auth'
import account from './account'
import order from './order'
import ourStaff from './ourStaff'
import contacts from './contacts'
import showroom from './showroom'
import news from './news'
import dmto from './dmto'
import wp from './wp'

export default {
  app,
  cart,
  filters,
  favorites,
  compareList,
  listings,
  details,
  customItem,
  bestsellers,
  localPrices,
  auth,
  account,
  order,
  ourStaff,
  contacts,
  showroom,
  news,
  dmto,
  wp
}

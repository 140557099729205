<template lang="pug">
  svg(
    @click="$emit('click')", 
    :class="{ 'is-active': isActive, hide: hideIcon }", 
    :width="iconWidth", 
    :height="iconHeight", 
    preserveAspectRatio="xMidYMid meet", 
    :aria-labelledby="labelledby", 
    :aria-hidden="hideAria", 
    xmlns="http://www.w3.org/2000/svg",
    :viewBox="viewBox",
    ref="svg"
  )
    title(:id="titleId", :class="iconName", lang="en") {{ startCase(iconName) }}
    desc(:id="descId", v-if="desc") {{ desc }}
</template>

<script>
import startCase from 'lodash/startCase'
import { mapGetters, mapActions } from 'vuex'

// Track in-flight requests to prevent duplicates
const pendingRequests = {}

export default {
  name: 'IconComponent',

  props: {
    iconName: {
      type: String,
      required: true
    },

    width: {
      type: [Number, String],
      default: 20
    },

    height: {
      type: [Number, String],
      default: 20
    },

    iconColor: {
      type: String,
      default: ''
    },

    desc: {
      type: String,
      default: ''
    },

    showName: {
      type: Boolean,
      default: false
    },

    isActive: {
      type: Boolean,
      default: false
    },

    hideAria: {
      type: Boolean,
      default: true
    }
  },

  data: () => ({
    hideIcon: false,
    localWidth: null,
    localHeight: null,
    viewBox: '0 0 0 0'
  }),

  computed: {
    ...mapGetters('app', ['availableIcons', 'getCachedIcon']),

    labelledby() {
      if (!this.desc) return this.titleId
      return [this.titleId, this.descId].join(' ')
    },

    titleId() {
      return 'title-' + this._uid
    },

    descId() {
      return 'desc-' + this._uid
    },

    iconWidth() {
      return this.localWidth || this.width
    },

    iconHeight() {
      return this.localHeight || this.height
    }
  },

  watch: {
    iconName: {
      immediate: true,
      handler: 'loadIcon'
    },

    iconColor: {
      handler: 'updateIconColor',
      immediate: true
    }
  },

  methods: {
    ...mapActions('app', ['cacheIcon']),

    startCase,

    async loadIcon() {
      // Skip icon loading on server-side
      if (process.server) {
        return
      }

      // Check if icon exists in the store
      if (!this.availableIcons.includes(this.iconName)) {
        console.warn(`Icon "${this.iconName}" is not available`)
        this.hideIcon = true
        return
      }

      try {
        let svgContent

        // Check if icon is in cache
        const cachedSvgString = this.getCachedIcon(this.iconName)

        if (cachedSvgString) {
          svgContent = cachedSvgString
        } else {
          // Check if there's already a pending request for this icon
          if (pendingRequests[this.iconName]) {
            // Wait for the existing request to complete
            svgContent = await pendingRequests[this.iconName]
          } else {
            // Create a new request and store its promise
            const iconUrl = new URL(
              `/icons/${this.iconName}.svg`,
              window.location.origin
            ).href

            // Create a promise for this request and store it
            pendingRequests[this.iconName] = fetch(iconUrl)
              .then((response) => response.text())
              .then((text) => {
                // Cache the result
                if (text.includes('<svg')) {
                  this.cacheIcon({
                    name: this.iconName,
                    element: text
                  })
                }
                // Clean up the pending request
                delete pendingRequests[this.iconName]
                return text
              })
              .catch((error) => {
                // Clean up on error
                delete pendingRequests[this.iconName]
                throw error
              })

            // Wait for our request to complete
            svgContent = await pendingRequests[this.iconName]
          }

          if (!svgContent.includes('<svg')) {
            console.error(`Invalid SVG content for "${this.iconName}"`)
            this.hideIcon = true
            return
          }
        }

        // Parse SVG content
        const parser = new DOMParser()
        const svgDoc = parser.parseFromString(svgContent, 'image/svg+xml')
        const svgElement = svgDoc.querySelector('svg')

        if (!svgElement) {
          console.error(`Failed to parse SVG for "${this.iconName}"`)
          this.hideIcon = true
          return
        }

        // Get viewBox from parsed SVG
        this.viewBox =
          svgElement.getAttribute('viewbox') ||
          svgElement.getAttribute('viewBox') ||
          '0 0 0 0'

        // Wait for next tick to ensure $refs.svg is available
        this.$nextTick(() => {
          if (this.$refs.svg) {
            // Get references to title and desc elements before clearing
            const titleElement = this.$refs.svg.querySelector(
              `#${this.titleId}`
            )
            const descElement = this.$refs.svg.querySelector(`#${this.descId}`)

            // Store their content if they exist
            const titleContent = titleElement ? titleElement.innerHTML : ''
            const descContent = descElement ? descElement.innerHTML : ''

            // Clear current SVG content
            this.$refs.svg.innerHTML = ''

            // Re-create title element
            if (titleElement) {
              const newTitle = document.createElement('title')
              newTitle.id = this.titleId
              newTitle.className = this.iconName
              newTitle.setAttribute('lang', 'en')
              newTitle.innerHTML = titleContent || this.startCase(this.iconName)
              this.$refs.svg.appendChild(newTitle)
            }

            // Re-create desc element if needed
            if (descElement && this.desc) {
              const newDesc = document.createElement('desc')
              newDesc.id = this.descId
              newDesc.innerHTML = descContent || this.desc
              this.$refs.svg.appendChild(newDesc)
            }

            // Copy all content of SVG, including defs, styles and other elements
            // 1. Copy all attributes from the original SVG (except viewBox, which is already set)
            Array.from(svgElement.attributes)
              .filter(
                (attr) =>
                  attr.name !== 'viewBox' &&
                  attr.name !== 'viewbox' &&
                  attr.name !== 'width' &&
                  attr.name !== 'height'
              )
              .forEach((attr) => {
                this.$refs.svg.setAttribute(attr.name, attr.value)
              })

            // 2. Copy all content of SVG, including defs, styles and etc.
            // Use innerHTML instead of outerHTML to copy only content
            const allContent = svgElement.innerHTML

            // Remove title and desc tags from extracted content if they exist
            // We already added our own above
            const contentWithoutTitleDesc = allContent
              .replace(/<title[^>]*>.*?<\/title>/gi, '')
              .replace(/<desc[^>]*>.*?<\/desc>/gi, '')

            this.$refs.svg.insertAdjacentHTML(
              'beforeend',
              contentWithoutTitleDesc
            )

            this.hideIcon = false
            this.updateIconColor()
          }
        })
      } catch (error) {
        console.error(`Error loading icon "${this.iconName}":`, error)
        this.hideIcon = true
      }
    },

    updateIconColor() {
      if (!this.$refs.svg || !this.iconColor) return

      // Update fill only for path elements
      this.$refs.svg.querySelectorAll('path').forEach((path) => {
        // For path without fill or with default fill="#2e2e2e"
        if (
          !path.getAttribute('fill') ||
          path.getAttribute('fill') === '#2e2e2e'
        ) {
          path.setAttribute('fill', this.iconColor)
        }
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.hide
  display: none

svg
  :deep(path)
    transition: fill 0.3s ease
</style>

export default ($axios, isDev) => ({
  fetchInitialData: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/metadata/initial`
    )
    return data
  },

  fetchVersion: async () => {
    const data = await $axios.$get('/version.json')
    return data
  },

  fetchCountryInfo: async (ip) => {
    const apiStack = $axios.create()

    const data = await apiStack.get(
      `https://api.ipstack.com/${ip}?access_key=40df478658bc3ac5f7d64ce5d38325da`
    )

    return data
  },

  saveString: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/anonymous-data`,
      { data: query }
    )
    return data
  },

  getString: async (query) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/account/anonymous-data/${query}`
    )
    return data
  }
})

import OnDemand from '~~/utils/helpers/onDemand'

export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/hammer.min.js?v=2.0.8'
  })

  inject('hammer', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve()
          if (callback) callback()
        })
      })
  })
}

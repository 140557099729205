export default ($axios) => ({
  fetchOrderHistory: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/order/history`
    )
    return data
  },

  fetchOrderSteps: async (orderId, stepId) => {
    const data = await $axios.$get(
      `/public/${process.env.siteName}/order/order-step/${orderId}/${stepId}`
    )
    return data
  }
})

export default ($axios) => ({
  signUpProcess: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/signup`,
      query
    )
    return data
  },

  signInProcess: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/account/auth`,
      query
    )
    return data
  }
})

export default ($axios) => ({
  fetchDesignersList: async (query) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/preview/dmto/designer/list`,
      query
    )
    return data
  },

  fetchDesignerInfo: async (query) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/preview/dmto/designer/${query}`
    )
    return data
  },

  fetchDesignerCollection: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/preview/dmto/designer-products`,
      query
    )
    return data
  },

  fetchDesignerItem: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/preview/dmto/detail`,
      query
    )
    return data
  },

  fetchFinalPrice: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/preview/dmto/price`,
      query
    )
    return data
  }
})

import OnDemand from '~~/utils/helpers/onDemand'

const apiKey = {
  ruby: 'df8e93ea7e41bf7d0851e946331884415a253209',
  emerald: '5c640db64249fbd2a98cb6c4aa90b25c72ec7c34',
  gemstone: '846145c5699637710c75d9d5537e80a9bc55750d',
  sapphire: '8abf49f664cfaa61428d7c54629daf8710fa8c22'
}

export default (ctx, inject) => {
  const loader = new OnDemand({
    script: 'https://embedsocial.com/cdn/ht.js'
  })

  const addEmbedSocialScript = (callback) => {
    const currentApiKey = apiKey[process.env.siteName]
    const esContainer = document.querySelector('.embedsocial-hashtag')

    if (esContainer) esContainer.setAttribute('data-ref', currentApiKey)

    loader.load(() => {
      if (callback) callback()
    })
  }

  inject('embedSocial', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        try {
          addEmbedSocialScript(callback)
          resolve()
        } catch (error) {
          console.error('EmbedSocial loading failed:', error)
          reject(error)
        }
      }),
    kill: () => {
      loader.scriptsLoaded = 0
      loader.isLoading = false
      loader.callbacks = []
      const script = document.querySelector(
        'script[src="https://embedsocial.com/cdn/ht.js"]'
      )
      if (script) script.parentNode.removeChild(script)
    }
  })
}

<template lang="pug">
  .picture-wrapper
    picture(ref="picture", @mouseenter="$emit('mouseenter')", @click="$emit('click')")
      source(v-if="webpSrc", :data-srcset="webpSrc", type="image/webp")
      img.lazy(ref="img", v-if="lazy", @load="onLoaded", class="lazy", :data-srcset="imgSrc[0]", :data-src="imgSrc[1]", :alt="alt", :width="width", :height="height")
      img(ref="img", v-else, @load="onLoaded", :srcset="imgSrc[0]", :src="imgSrc[1]", :alt="alt", :width="width", :height="height")
</template>

<script>
import { mapState } from 'vuex'

const images =
  require(`~~/projects/${process.env.siteName}/static/images/imageMetadata`).default

export default {
  props: {
    src: {
      type: String,
      required: true
    },

    width: {
      type: String,
      default: ''
    },

    height: {
      type: String,
      default: ''
    },

    preloader: {
      type: Boolean,
      default: false
    },

    alt: {
      type: String,
      default: 'Image'
    },

    version: {
      type: String,
      default: ''
    },

    lazy: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      loading: true
    }
  },

  computed: {
    ...mapState({
      imagesVersion: (state) => state.imagesVersion
    }),

    images() {
      return images
    },

    srcNormalized() {
      if (!this.src) return ''
      return this.src
    },

    imageVersion() {
      if (this.version) return '?v=' + this.version
      if (this.imagesVersion) return '?v=' + this.imagesVersion
      return ''
    },

    imgSrc() {
      if (!this.src) return ''
      if (/https?:\/\//.test(this.srcNormalized))
        return [this.srcNormalized, this.srcNormalized]
      if (!images[this.srcNormalized]) {
        console.log('image not found', this.srcNormalized)
        return [
          `${images['not-available@2x.jpg'].normal} 1x, ${images['not-available@2x.jpg'].retina} 2x`
        ]
      }
      return [
        `${images[this.srcNormalized].normal}${this.imageVersion} 1x, ${
          images[this.srcNormalized].retina
        }${this.imageVersion} 2x`,
        `${images[this.srcNormalized].normal}${this.imageVersion}`
      ]
    },

    webpSrc() {
      if (!this.srcNormalized || /https?:\/\//.test(this.srcNormalized))
        return ''
      const ext = this.srcNormalized.match(/\.([\w]+$)/)[1]
      if (['gif', 'svg'].includes(ext)) return ''
      if (!images[this.srcNormalized]) {
        return [
          `${images['not-available@2x.jpg'].webp} 1x, ${images['not-available@2x.jpg'].retinaWebp} 2x`
        ]
      }
      return `${images[this.srcNormalized].webp}${this.imageVersion} 1x, ${
        images[this.srcNormalized].retinaWebp
      }${this.imageVersion} 2x`
    }
  },

  watch: {
    imgSrc(val) {
      this.loading = true
      this.$nextTick(() => {
        if (!this.$refs.picture) return
        const { childNodes } = this.$refs.picture

        for (let i = 0; i < childNodes.length; i++) {
          const node = childNodes[i]
          if (!node.dataset) continue // vue commented node
          const params = ['srcset', 'src']

          for (let j = 0; j < params.length; j++) {
            const param = node.dataset[params[j]]
            if (!param) continue
            node.setAttribute(params[j], param)
          }
        }
      })
    }
  },

  created() {
    if (process.client) {
      if (!this.preloader) this.loading = false
    }
  },

  mounted() {
    if (window.lazyUpdate) window.lazyUpdate()
  },

  methods: {
    forceLoad() {
      if (!window.LazyLoad) {
        this.$root.$once('lazy-loaded', () => {
          if (this.$refs.img) {
            this.$nextTick(() => {
              window.LazyLoad.load(this.$refs.img)
            })
          }
        })
        return
      }
      if (this.$refs.img) window.LazyLoad.load(this.$refs.img)
    },

    onLoaded() {
      this.$nextTick(() => {
        this.loading = false
        this.$emit('load')
      })
    }
  }
}
</script>

<style lang="sass" scoped>
.picture-wrapper
  position: relative

picture
  display: block

img
  display: block
  margin: auto

img:not([srcset])
  visibility: hidden
</style>

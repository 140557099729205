export default ($axios) => ({
  fetchStonesList: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/gemstone`,
      query
    )
    return data
  },

  fetchPairsList: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/gemstone/pairs`,
      query
    )
    return data
  },

  fetchSettingsList: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/setting`,
      query
    )
    return data
  },

  fetchJewelryList: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/jewelry`,
      query
    )
    return data
  },

  fetchBandsList: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/band`,
      query
    )
    return data
  }
})

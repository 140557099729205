export default ($axios) => ({
  scheduleVisit: async (query) => {
    const data = await $axios.$post(
      `public/${process.env.siteName}/cart/schedule-visit`,
      query
    )
    return data
  },

  fetchItems: async (query) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/search/${query}`
    )
    return data
  }
})

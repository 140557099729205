import OnDemand from '~~/utils/helpers/onDemand'

const apiKey = {
  ruby: '66ade3c695a58314549ca32d3626f4cffdd06773d091916ab7840527fc4ac9d7',
  emerald: 'd4c88f7296aa4753a14b616bbdba2159dafb8270ca9242faa9eb0931f0d36638',
  gemstone: 'a81a25ce07ce42e4827686094264c63647a1a896e9a04f98ba0cfacce3e95d8c',
  sapphire: '98da5c2ac519405db5f4e75c7d042b3abf5bb5fcc71e4fcc9274da25f260fd7f'
}

export default (ctx, inject) => {
  const loader = new OnDemand({
    script: `https://userlike-cdn-widgets.s3-eu-west-1.amazonaws.com/${
      apiKey[process.env.siteName]
    }.js`
  })

  inject('userlike', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          setTimeout(() => {
            window.userlikeStartChat = function () {
              try {
                document
                  .querySelectorAll('*[id^="userlike"] iframe')[0]
                  .contentWindow.document.getElementsByTagName('button')[0]
                  .click()
              } catch (e) {
                console.log(e)
              }
            }
            resolve()
            if (callback) callback()
          }, 1000)
        })
      })
  })
}

import Middleware from './middleware'
import { Auth, authMiddleware, ExpiredAuthSessionError } from '~auth/runtime'

// Active schemes
import { LocalScheme } from '~auth/runtime'

Middleware.auth = authMiddleware

export default function (ctx, inject) {
  // Options
  const options = {
  "resetOnError": true,
  "ignoreExceptions": false,
  "scopeKey": "scope",
  "rewriteRedirects": true,
  "fullPathRedirect": false,
  "watchLoggedIn": true,
  "redirect": {
    "login": "/auth/",
    "logout": false,
    "home": false,
    "callback": "/login"
  },
  "vuex": {
    "namespace": "auth"
  },
  "cookie": {
    "prefix": "auth.",
    "options": {
      "path": "/",
      "sameSite": "lax",
      "secure": false
    }
  },
  "localStorage": {
    "prefix": "auth."
  },
  "defaultStrategy": "register"
}

  // Create a new Auth instance
  const $auth = new Auth(ctx, options)

  // Register strategies
  // register
  $auth.registerStrategy('register', new LocalScheme($auth, {
  "token": {
    "maxAge": 18000,
    "property": "token",
    "type": "Bearer"
  },
  "user": {
    "property": false,
    "autoFetch": false
  },
  "endpoints": {
    "login": {
      "url": "/public/gemstone/account/signup",
      "method": "post"
    },
    "user": {
      "url": "/public/gemstone/account/profile",
      "method": "get"
    },
    "logout": false
  },
  "name": "register"
}))

  // login
  $auth.registerStrategy('login', new LocalScheme($auth, {
  "token": {
    "maxAge": 18000,
    "property": "token",
    "type": "Bearer"
  },
  "user": {
    "property": false,
    "autoFetch": false
  },
  "endpoints": {
    "login": {
      "url": "/public/gemstone/account/auth",
      "method": "post"
    },
    "user": {
      "url": "/public/gemstone/account/profile",
      "method": "get"
    },
    "logout": false
  },
  "name": "login"
}))

  // Inject it to nuxt context as $auth
  inject('auth', $auth)
  ctx.$auth = $auth

  // Initialize auth
  return $auth.init().catch(error => {
    if (process.client) {
      // Don't console log expired auth session errors. This error is common, and expected to happen.
      // The error happens whenever the user does an ssr request (reload/initial navigation) with an expired refresh
      // token. We don't want to log this as an error.
      if (error instanceof ExpiredAuthSessionError) {
        return
      }

      console.error('[ERROR] [AUTH]', error)
    }
  })
}

// import convertor from `xml-js`
//
// const staticAxios = (axios) =>
//   axios.create({
//     baseURL: process.env.static,
//     transformResponse: [
//       function(xml) {
//         const data = convertor.xml2json(xml, { compact: true })
//         return JSON.parse(data)
//       }
//     ]
//   })

export default ($axios) => ({
  fetchTeams: async () => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/metadata/ourteams`
    )
    return data
  },

  fetchMember: async ({ bio }) => {
    const data = await $axios.$get(
      `public/${process.env.siteName}/metadata/teamdetail/${bio}`
    )
    return data
  }

  // fetchTeams: async () => {
  //   const data = await staticAxios($axios).$get(`/xml/staff/rubyteams.xml`)
  //   return data
  // },
  //
  // fetchMember: async ({ bio }) => {
  //   const data = await staticAxios($axios).$get(`/${bio}`)
  //   return data
  // }
})

<script>
export default {
  props: {
    type: {
      type: String,
      validator: (val) => ['nuxt', 'native'].includes(val),
      default: 'nuxt'
    },

    to: {
      type: [String, Object],
      default: ''
    },

    disabled: {
      type: Boolean,
      default: false
    },

    stop: {
      type: Boolean,
      default: false
    },

    noFollow: {
      type: Boolean,
      default: false
    },

    prefetch: {
      type: Boolean,
      default: false
    },

    target: {
      type: String,
      validator: (val) => ['_blank', '_self', '_parent', '_top'].includes(val),
      default: '_self'
    }
  },

  computed: {
    path() {
      return this.localePath(this.to)
    }
  },

  render(createElement) {
    if (this.disabled) return createElement('span', {}, this.$slots.default)
    if (this.type === 'native') {
      const rels = ['noreferrer']
      if (this.target === '_blank') rels.push('noopener')
      if (this.noFollow) rels.push('nofollow')

      return createElement(
        'a',
        {
          attrs: {
            href: this.to,
            target: this.target,
            rel: rels.join(' ')
          }
        },
        this.$slots.default
      )
    }
    const options = {
      props: {
        to: this.path,
        target: this.target,
        prefetch: this.prefetch
      }
    }

    if (this.stop) options.props.event = ''
    return createElement(
      this.$options.components.NuxtLink,
      options,
      this.$slots.default
    )
  }
}
</script>

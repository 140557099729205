import OnDemand from '~~/utils/helpers/onDemand'

const apiKey = {
  ruby: 'G-XYCXVMTQ40',
  emerald: 'G-MPHM2K3VNK',
  gemstone: 'G-XWZEXSR87Z'
}
// const apiKey = {
//   ruby: 'UA-187898-6',
//   emerald: 'UA-187898-5'
// }

/* global ga */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script: '/js/analytics.js'
  })

  inject('analytics', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          ga(
            'create',
            process.env.isDev ? 'UA-153554273-1' : apiKey[process.env.siteName],
            'auto'
          )
          ga('send', 'pageview', location.pathname)
          resolve()
          if (callback) callback()
        })
      })
  })
}

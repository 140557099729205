import Vue from 'vue'
import Vuex from 'vuex'
import Meta from 'vue-meta'
import ClientOnly from 'vue-client-only'
import NoSsr from 'vue-no-ssr'
import { createRouter } from './router.js'
import NuxtChild from './components/nuxt-child.js'
import NuxtError from './components/nuxt-error.vue'
import Nuxt from './components/nuxt.js'
import App from './App.js'
import { setContext, getLocation, getRouteData, normalizeError } from './utils'
import { createStore } from './store.js'

/* Plugins */

import nuxt_plugin_sentryserver_9e4d35ce from 'nuxt_plugin_sentryserver_9e4d35ce' // Source: ./sentry.server.js (mode: 'server')
import nuxt_plugin_sentryclient_4b1ff391 from 'nuxt_plugin_sentryclient_4b1ff391' // Source: ./sentry.client.js (mode: 'client')
import nuxt_plugin_plugin_4f294ba1 from 'nuxt_plugin_plugin_4f294ba1' // Source: ./nuxt-perfect-cache/plugin.js (mode: 'all')
import nuxt_plugin_cookieuniversalnuxt_32a51ffd from 'nuxt_plugin_cookieuniversalnuxt_32a51ffd' // Source: ./cookie-universal-nuxt.js (mode: 'all')
import nuxt_plugin_pluginutils_2b8af886 from 'nuxt_plugin_pluginutils_2b8af886' // Source: ./nuxt-i18n/plugin.utils.js (mode: 'all')
import nuxt_plugin_pluginrouting_2aaf7c51 from 'nuxt_plugin_pluginrouting_2aaf7c51' // Source: ./nuxt-i18n/plugin.routing.js (mode: 'all')
import nuxt_plugin_pluginmain_e7438e84 from 'nuxt_plugin_pluginmain_e7438e84' // Source: ./nuxt-i18n/plugin.main.js (mode: 'all')
import nuxt_plugin_deviceplugin_5a018640 from 'nuxt_plugin_deviceplugin_5a018640' // Source: ./device.plugin.js (mode: 'all')
import nuxt_plugin_workbox_0a40039e from 'nuxt_plugin_workbox_0a40039e' // Source: ./workbox.js (mode: 'client')
import nuxt_plugin_iconplugin_8dc7ac90 from 'nuxt_plugin_iconplugin_8dc7ac90' // Source: ./pwa/icon.plugin.js (mode: 'all')
import nuxt_plugin_axios_1c18ca55 from 'nuxt_plugin_axios_1c18ca55' // Source: ./axios.js (mode: 'all')
import nuxt_plugin_store_68c30c1b from 'nuxt_plugin_store_68c30c1b' // Source: ../../plugins/store.js (mode: 'all')
import nuxt_plugin_eventBus_50102428 from 'nuxt_plugin_eventBus_50102428' // Source: ../../plugins/eventBus.js (mode: 'all')
import nuxt_plugin_api_12e0b756 from 'nuxt_plugin_api_12e0b756' // Source: ../../plugins/api (mode: 'all')
import nuxt_plugin_axios_dd88d81e from 'nuxt_plugin_axios_dd88d81e' // Source: ../../plugins/axios (mode: 'all')
import nuxt_plugin_analytics_4ff0ae50 from 'nuxt_plugin_analytics_4ff0ae50' // Source: ../../plugins/analytics.client (mode: 'client')
import nuxt_plugin_vueobserver_00de8b62 from 'nuxt_plugin_vueobserver_00de8b62' // Source: ../../plugins/vue-observer (mode: 'all')
import nuxt_plugin_events_4fe2528a from 'nuxt_plugin_events_4fe2528a' // Source: ../../plugins/events.client (mode: 'client')
import nuxt_plugin_globalcomponents_53cd030a from 'nuxt_plugin_globalcomponents_53cd030a' // Source: ../../plugins/global-components (mode: 'all')
import nuxt_plugin_fonts_f86d91cc from 'nuxt_plugin_fonts_f86d91cc' // Source: ../../plugins/fonts.client (mode: 'client')
import nuxt_plugin_interpolationLinks_25e68b5f from 'nuxt_plugin_interpolationLinks_25e68b5f' // Source: ../../plugins/interpolationLinks.client (mode: 'client')
import nuxt_plugin_helpers_fa9f0980 from 'nuxt_plugin_helpers_fa9f0980' // Source: ../../plugins/helpers (mode: 'all')
import nuxt_plugin_localStorage_7793cf24 from 'nuxt_plugin_localStorage_7793cf24' // Source: ../../plugins/localStorage.client (mode: 'client')
import nuxt_plugin_vuejsmodal_05be79b5 from 'nuxt_plugin_vuejsmodal_05be79b5' // Source: ../../plugins/vue-js-modal (mode: 'all')
import nuxt_plugin_metaTags_30df933a from 'nuxt_plugin_metaTags_30df933a' // Source: ../../plugins/metaTags (mode: 'all')
import nuxt_plugin_clearQueryParamsUrl_40839b50 from 'nuxt_plugin_clearQueryParamsUrl_40839b50' // Source: ../../plugins/clearQueryParamsUrl.client (mode: 'client')
import nuxt_plugin_iconsloader_7ecac774 from 'nuxt_plugin_iconsloader_7ecac774' // Source: ../../plugins/icons-loader.server (mode: 'server')
import nuxt_plugin_auth_9be47f8a from 'nuxt_plugin_auth_9be47f8a' // Source: ./auth.js (mode: 'all')
import nuxt_plugin_auth_49360a66 from 'nuxt_plugin_auth_49360a66' // Source: ../../plugins/auth (mode: 'all')
import nuxt_plugin_integrationsaccordionclient56a864dd_00a5013f from 'nuxt_plugin_integrationsaccordionclient56a864dd_00a5013f' // Source: ./integrations.accordion.client.56a864dd.js (mode: 'client')
import nuxt_plugin_integrationsagGridclientf300b1da_518d7242 from 'nuxt_plugin_integrationsagGridclientf300b1da_518d7242' // Source: ./integrations.agGrid.client.f300b1da.js (mode: 'client')
import nuxt_plugin_integrationsanalyticsclientbc37f4ca_590a2d26 from 'nuxt_plugin_integrationsanalyticsclientbc37f4ca_590a2d26' // Source: ./integrations.analytics.client.bc37f4ca.js (mode: 'client')
import nuxt_plugin_integrationsbluebirdclient65eff39a_4c283dff from 'nuxt_plugin_integrationsbluebirdclient65eff39a_4c283dff' // Source: ./integrations.bluebird.client.65eff39a.js (mode: 'client')
import nuxt_plugin_integrationsbodyScrollLockclient00afc0e1_f31c2ea2 from 'nuxt_plugin_integrationsbodyScrollLockclient00afc0e1_f31c2ea2' // Source: ./integrations.bodyScrollLock.client.00afc0e1.js (mode: 'client')
import nuxt_plugin_integrationschargeAfterclient1be8415d_9c64fcf6 from 'nuxt_plugin_integrationschargeAfterclient1be8415d_9c64fcf6' // Source: ./integrations.chargeAfter.client.1be8415d.js (mode: 'client')
import nuxt_plugin_integrationsclipboardclientecec18aa_68937ce4 from 'nuxt_plugin_integrationsclipboardclientecec18aa_68937ce4' // Source: ./integrations.clipboard.client.ecec18aa.js (mode: 'client')
import nuxt_plugin_integrationsdatePickerclient7d86b03a_26db26e4 from 'nuxt_plugin_integrationsdatePickerclient7d86b03a_26db26e4' // Source: ./integrations.datePicker.client.7d86b03a.js (mode: 'client')
import nuxt_plugin_integrationsdraggableclient3b95cae2_6894993a from 'nuxt_plugin_integrationsdraggableclient3b95cae2_6894993a' // Source: ./integrations.draggable.client.3b95cae2.js (mode: 'client')
import nuxt_plugin_integrationsembedsocialclientd4dd104a_2a9c49fb from 'nuxt_plugin_integrationsembedsocialclientd4dd104a_2a9c49fb' // Source: ./integrations.embedsocial.client.d4dd104a.js (mode: 'client')
import nuxt_plugin_integrationsextendSDKclienta7d9c756_23f78f7d from 'nuxt_plugin_integrationsextendSDKclienta7d9c756_23f78f7d' // Source: ./integrations.extendSDK.client.a7d9c756.js (mode: 'client')
import nuxt_plugin_integrationsgoogleBadgecliente7999782_3f94dc89 from 'nuxt_plugin_integrationsgoogleBadgecliente7999782_3f94dc89' // Source: ./integrations.googleBadge.client.e7999782.js (mode: 'client')
import nuxt_plugin_integrationsgoogleMapsclient1464ad12_2cef735c from 'nuxt_plugin_integrationsgoogleMapsclient1464ad12_2cef735c' // Source: ./integrations.googleMaps.client.1464ad12.js (mode: 'client')
import nuxt_plugin_integrationsgoogleSurveyclient1ee509fa_066698a8 from 'nuxt_plugin_integrationsgoogleSurveyclient1ee509fa_066698a8' // Source: ./integrations.googleSurvey.client.1ee509fa.js (mode: 'client')
import nuxt_plugin_integrationsgtagclientc1104c4c_3f6b0d6e from 'nuxt_plugin_integrationsgtagclientc1104c4c_3f6b0d6e' // Source: ./integrations.gtag.client.c1104c4c.js (mode: 'client')
import nuxt_plugin_integrationshammerclient7a7038cd_567de410 from 'nuxt_plugin_integrationshammerclient7a7038cd_567de410' // Source: ./integrations.hammer.client.7a7038cd.js (mode: 'client')
import nuxt_plugin_integrationshlsclient0c77b7f8_25af5658 from 'nuxt_plugin_integrationshlsclient0c77b7f8_25af5658' // Source: ./integrations.hls.client.0c77b7f8.js (mode: 'client')
import nuxt_plugin_integrationsimaskclient41ea1cac_8fe34d0a from 'nuxt_plugin_integrationsimaskclient41ea1cac_8fe34d0a' // Source: ./integrations.imask.client.41ea1cac.js (mode: 'client')
import nuxt_plugin_integrationsiv3dclient1f9faac5_45d7db56 from 'nuxt_plugin_integrationsiv3dclient1f9faac5_45d7db56' // Source: ./integrations.iv3d.client.1f9faac5.js (mode: 'client')
import nuxt_plugin_integrationslazyloadclient1f7825be_7a951f1e from 'nuxt_plugin_integrationslazyloadclient1f7825be_7a951f1e' // Source: ./integrations.lazyload.client.1f7825be.js (mode: 'client')
import nuxt_plugin_integrationsmicrosoftClarityclient143103e6_b04123a8 from 'nuxt_plugin_integrationsmicrosoftClarityclient143103e6_b04123a8' // Source: ./integrations.microsoftClarity.client.143103e6.js (mode: 'client')
import nuxt_plugin_integrationsmodernizrclient2d518cf7_6edf3971 from 'nuxt_plugin_integrationsmodernizrclient2d518cf7_6edf3971' // Source: ./integrations.modernizr.client.2d518cf7.js (mode: 'client')
import nuxt_plugin_integrationsnouisliderclient838a4a86_5e59d8e2 from 'nuxt_plugin_integrationsnouisliderclient838a4a86_5e59d8e2' // Source: ./integrations.nouislider.client.838a4a86.js (mode: 'client')
import nuxt_plugin_integrationsperfectScrollbarclient399c97a0_70fe7b9e from 'nuxt_plugin_integrationsperfectScrollbarclient399c97a0_70fe7b9e' // Source: ./integrations.perfectScrollbar.client.399c97a0.js (mode: 'client')
import nuxt_plugin_integrationspopperclient3bbd309a_7b0ca5b6 from 'nuxt_plugin_integrationspopperclient3bbd309a_7b0ca5b6' // Source: ./integrations.popper.client.3bbd309a.js (mode: 'client')
import nuxt_plugin_integrationsrecaptchaclient3fbd89bc_243deb1f from 'nuxt_plugin_integrationsrecaptchaclient3fbd89bc_243deb1f' // Source: ./integrations.recaptcha.client.3fbd89bc.js (mode: 'client')
import nuxt_plugin_integrationsrejoinerclient3c5ab3de_2e61c34f from 'nuxt_plugin_integrationsrejoinerclient3c5ab3de_2e61c34f' // Source: ./integrations.rejoiner.client.3c5ab3de.js (mode: 'client')
import nuxt_plugin_integrationssocialSharingclient767477f8_247fc4a0 from 'nuxt_plugin_integrationssocialSharingclient767477f8_247fc4a0' // Source: ./integrations.socialSharing.client.767477f8.js (mode: 'client')
import nuxt_plugin_integrationssweetalertclient4d00fa73_43381d98 from 'nuxt_plugin_integrationssweetalertclient4d00fa73_43381d98' // Source: ./integrations.sweetalert.client.4d00fa73.js (mode: 'client')
import nuxt_plugin_integrationsswiperclient28a1cfff_fb112354 from 'nuxt_plugin_integrationsswiperclient28a1cfff_fb112354' // Source: ./integrations.swiper.client.28a1cfff.js (mode: 'client')
import nuxt_plugin_integrationstabulatorclient473d2152_4241bf2a from 'nuxt_plugin_integrationstabulatorclient473d2152_4241bf2a' // Source: ./integrations.tabulator.client.473d2152.js (mode: 'client')
import nuxt_plugin_integrationstippyclient3b1b93f9_d241e1e8 from 'nuxt_plugin_integrationstippyclient3b1b93f9_d241e1e8' // Source: ./integrations.tippy.client.3b1b93f9.js (mode: 'client')
import nuxt_plugin_integrationsuserlikeclient4bbb3aae_6b972ef6 from 'nuxt_plugin_integrationsuserlikeclient4bbb3aae_6b972ef6' // Source: ./integrations.userlike.client.4bbb3aae.js (mode: 'client')

// Component: <ClientOnly>
Vue.component(ClientOnly.name, ClientOnly)

// TODO: Remove in Nuxt 3: <NoSsr>
Vue.component(NoSsr.name, {
  ...NoSsr,
  render (h, ctx) {
    if (process.client && !NoSsr._warned) {
      NoSsr._warned = true

      console.warn('<no-ssr> has been deprecated and will be removed in Nuxt 3, please use <client-only> instead')
    }
    return NoSsr.render(h, ctx)
  }
})

// Component: <NuxtChild>
Vue.component(NuxtChild.name, NuxtChild)
Vue.component('NChild', NuxtChild)

// Component NuxtLink is imported in server.js or client.js

// Component: <Nuxt>
Vue.component(Nuxt.name, Nuxt)

Object.defineProperty(Vue.prototype, '$nuxt', {
  get() {
    const globalNuxt = this.$root ? this.$root.$options.$nuxt : null
    if (process.client && !globalNuxt && typeof window !== 'undefined') {
      return window.$nuxt
    }
    return globalNuxt
  },
  configurable: true
})

Vue.use(Meta, {"keyName":"head","attribute":"data-n-head","ssrAttribute":"data-n-head-ssr","tagIDKeyName":"hid"})

const defaultTransition = {"name":"page","mode":"out-in","appear":false,"appearClass":"appear","appearActiveClass":"appear-active","appearToClass":"appear-to"}

const originalRegisterModule = Vuex.Store.prototype.registerModule

function registerModule (path, rawModule, options = {}) {
  const preserveState = process.client && (
    Array.isArray(path)
      ? !!path.reduce((namespacedState, path) => namespacedState && namespacedState[path], this.state)
      : path in this.state
  )
  return originalRegisterModule.call(this, path, rawModule, { preserveState, ...options })
}

async function createApp(ssrContext, config = {}) {
  const store = createStore(ssrContext)
  const router = await createRouter(ssrContext, config, { store })

  // Add this.$router into store actions/mutations
  store.$router = router

  // Fix SSR caveat https://github.com/nuxt/nuxt.js/issues/3757#issuecomment-414689141
  store.registerModule = registerModule

  // Create Root instance

  // here we inject the router and store to all child components,
  // making them available everywhere as `this.$router` and `this.$store`.
  const app = {
    head: {"title":"The Natural Gemstone Company","meta":[{"charset":"utf-8"},{"name":"p:domain_verify","content":"a7c4a4085a03860ccd3222ab1738ee5c"},{"name":"viewport","content":"width=device-width, initial-scale=1"},{"name":"theme-color","content":"#e2e2e2"},{"name":"google-site-verification","content":"0FjiTVFFeWVEUIp-xlE_IF7_lxFfMBTNlLw9Hz7n06Q"}],"script":[{"src":"https:\u002F\u002Ff86a65dca19a.edge.sdk.awswaf.com\u002Ff86a65dca19a\u002F7a001dea8b6e\u002Fchallenge.js","defer":true}],"link":[{"rel":"preconnect","href":"https:\u002F\u002F360images.thenaturalsapphirecompany.com\u002F"},{"rel":"preconnect","href":"https:\u002F\u002Fqa-images.thenaturalsapphirecompany.com\u002F"},{"rel":"dns-prefetch","href":"https:\u002F\u002Fqa-images.thenaturalsapphirecompany.com\u002F"},{"rel":"preconnect","href":"https:\u002F\u002Fservice.thenaturalsapphirecompany.com"},{"rel":"preload","as":"font","crossOrigin":"anonymous","href":"\u002Fstyles\u002Ffonts\u002FLora\u002Fwoff2\u002FLora-Regular.woff2"},{"rel":"preload","as":"font","crossOrigin":"anonymous","href":"\u002Fstyles\u002Ffonts\u002FLora\u002Fwoff2\u002FLora-Italic.woff2"},{"rel":"preload","as":"font","crossOrigin":"anonymous","href":"\u002Fstyles\u002Ffonts\u002FLato\u002Fwoff2\u002FLato-Regular.woff2"}],"style":[]},

    store,
    router,
    nuxt: {
      defaultTransition,
      transitions: [defaultTransition],
      setTransitions (transitions) {
        if (!Array.isArray(transitions)) {
          transitions = [transitions]
        }
        transitions = transitions.map((transition) => {
          if (!transition) {
            transition = defaultTransition
          } else if (typeof transition === 'string') {
            transition = Object.assign({}, defaultTransition, { name: transition })
          } else {
            transition = Object.assign({}, defaultTransition, transition)
          }
          return transition
        })
        this.$options.nuxt.transitions = transitions
        return transitions
      },

      err: null,
      dateErr: null,
      error (err) {
        err = err || null
        app.context._errored = Boolean(err)
        err = err ? normalizeError(err) : null
        let nuxt = app.nuxt // to work with @vue/composition-api, see https://github.com/nuxt/nuxt.js/issues/6517#issuecomment-573280207
        if (this) {
          nuxt = this.nuxt || this.$options.nuxt
        }
        nuxt.dateErr = Date.now()
        nuxt.err = err
        // Used in src/server.js
        if (ssrContext) {
          ssrContext.nuxt.error = err
        }
        return err
      }
    },
    ...App
  }

  // Make app available into store via this.app
  store.app = app

  const next = ssrContext ? ssrContext.next : location => app.router.push(location)
  // Resolve route
  let route
  if (ssrContext) {
    route = router.resolve(ssrContext.url).route
  } else {
    const path = getLocation(router.options.base, router.options.mode)
    route = router.resolve(path).route
  }

  // Set context to app.context
  await setContext(app, {
    store,
    route,
    next,
    error: app.nuxt.error.bind(app),
    payload: ssrContext ? ssrContext.payload : undefined,
    req: ssrContext ? ssrContext.req : undefined,
    res: ssrContext ? ssrContext.res : undefined,
    beforeRenderFns: ssrContext ? ssrContext.beforeRenderFns : undefined,
    beforeSerializeFns: ssrContext ? ssrContext.beforeSerializeFns : undefined,
    ssrContext
  })

  function inject(key, value) {
    if (!key) {
      throw new Error('inject(key, value) has no key provided')
    }
    if (value === undefined) {
      throw new Error(`inject('${key}', value) has no value provided`)
    }

    key = '$' + key
    // Add into app
    app[key] = value
    // Add into context
    if (!app.context[key]) {
      app.context[key] = value
    }

    // Add into store
    store[key] = app[key]

    // Check if plugin not already installed
    const installKey = '__nuxt_' + key + '_installed__'
    if (Vue[installKey]) {
      return
    }
    Vue[installKey] = true
    // Call Vue.use() to install the plugin into vm
    Vue.use(() => {
      if (!Object.prototype.hasOwnProperty.call(Vue.prototype, key)) {
        Object.defineProperty(Vue.prototype, key, {
          get () {
            return this.$root.$options[key]
          }
        })
      }
    })
  }

  // Inject runtime config as $config
  inject('config', config)

  if (process.client) {
    // Replace store state before plugins execution
    if (window.__NUXT__ && window.__NUXT__.state) {
      store.replaceState(window.__NUXT__.state)
    }
  }

  // Add enablePreview(previewData = {}) in context for plugins
  if (process.static && process.client) {
    app.context.enablePreview = function (previewData = {}) {
      app.previewData = Object.assign({}, previewData)
      inject('preview', previewData)
    }
  }
  // Plugin execution

  if (process.server && typeof nuxt_plugin_sentryserver_9e4d35ce === 'function') {
    await nuxt_plugin_sentryserver_9e4d35ce(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_sentryclient_4b1ff391 === 'function') {
    await nuxt_plugin_sentryclient_4b1ff391(app.context, inject)
  }

  if (typeof nuxt_plugin_plugin_4f294ba1 === 'function') {
    await nuxt_plugin_plugin_4f294ba1(app.context, inject)
  }

  if (typeof nuxt_plugin_cookieuniversalnuxt_32a51ffd === 'function') {
    await nuxt_plugin_cookieuniversalnuxt_32a51ffd(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginutils_2b8af886 === 'function') {
    await nuxt_plugin_pluginutils_2b8af886(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginrouting_2aaf7c51 === 'function') {
    await nuxt_plugin_pluginrouting_2aaf7c51(app.context, inject)
  }

  if (typeof nuxt_plugin_pluginmain_e7438e84 === 'function') {
    await nuxt_plugin_pluginmain_e7438e84(app.context, inject)
  }

  if (typeof nuxt_plugin_deviceplugin_5a018640 === 'function') {
    await nuxt_plugin_deviceplugin_5a018640(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_workbox_0a40039e === 'function') {
    await nuxt_plugin_workbox_0a40039e(app.context, inject)
  }

  if (typeof nuxt_plugin_iconplugin_8dc7ac90 === 'function') {
    await nuxt_plugin_iconplugin_8dc7ac90(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_1c18ca55 === 'function') {
    await nuxt_plugin_axios_1c18ca55(app.context, inject)
  }

  if (typeof nuxt_plugin_store_68c30c1b === 'function') {
    await nuxt_plugin_store_68c30c1b(app.context, inject)
  }

  if (typeof nuxt_plugin_eventBus_50102428 === 'function') {
    await nuxt_plugin_eventBus_50102428(app.context, inject)
  }

  if (typeof nuxt_plugin_api_12e0b756 === 'function') {
    await nuxt_plugin_api_12e0b756(app.context, inject)
  }

  if (typeof nuxt_plugin_axios_dd88d81e === 'function') {
    await nuxt_plugin_axios_dd88d81e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_analytics_4ff0ae50 === 'function') {
    await nuxt_plugin_analytics_4ff0ae50(app.context, inject)
  }

  if (typeof nuxt_plugin_vueobserver_00de8b62 === 'function') {
    await nuxt_plugin_vueobserver_00de8b62(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_events_4fe2528a === 'function') {
    await nuxt_plugin_events_4fe2528a(app.context, inject)
  }

  if (typeof nuxt_plugin_globalcomponents_53cd030a === 'function') {
    await nuxt_plugin_globalcomponents_53cd030a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_fonts_f86d91cc === 'function') {
    await nuxt_plugin_fonts_f86d91cc(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_interpolationLinks_25e68b5f === 'function') {
    await nuxt_plugin_interpolationLinks_25e68b5f(app.context, inject)
  }

  if (typeof nuxt_plugin_helpers_fa9f0980 === 'function') {
    await nuxt_plugin_helpers_fa9f0980(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_localStorage_7793cf24 === 'function') {
    await nuxt_plugin_localStorage_7793cf24(app.context, inject)
  }

  if (typeof nuxt_plugin_vuejsmodal_05be79b5 === 'function') {
    await nuxt_plugin_vuejsmodal_05be79b5(app.context, inject)
  }

  if (typeof nuxt_plugin_metaTags_30df933a === 'function') {
    await nuxt_plugin_metaTags_30df933a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_clearQueryParamsUrl_40839b50 === 'function') {
    await nuxt_plugin_clearQueryParamsUrl_40839b50(app.context, inject)
  }

  if (process.server && typeof nuxt_plugin_iconsloader_7ecac774 === 'function') {
    await nuxt_plugin_iconsloader_7ecac774(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_9be47f8a === 'function') {
    await nuxt_plugin_auth_9be47f8a(app.context, inject)
  }

  if (typeof nuxt_plugin_auth_49360a66 === 'function') {
    await nuxt_plugin_auth_49360a66(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsaccordionclient56a864dd_00a5013f === 'function') {
    await nuxt_plugin_integrationsaccordionclient56a864dd_00a5013f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsagGridclientf300b1da_518d7242 === 'function') {
    await nuxt_plugin_integrationsagGridclientf300b1da_518d7242(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsanalyticsclientbc37f4ca_590a2d26 === 'function') {
    await nuxt_plugin_integrationsanalyticsclientbc37f4ca_590a2d26(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsbluebirdclient65eff39a_4c283dff === 'function') {
    await nuxt_plugin_integrationsbluebirdclient65eff39a_4c283dff(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsbodyScrollLockclient00afc0e1_f31c2ea2 === 'function') {
    await nuxt_plugin_integrationsbodyScrollLockclient00afc0e1_f31c2ea2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationschargeAfterclient1be8415d_9c64fcf6 === 'function') {
    await nuxt_plugin_integrationschargeAfterclient1be8415d_9c64fcf6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsclipboardclientecec18aa_68937ce4 === 'function') {
    await nuxt_plugin_integrationsclipboardclientecec18aa_68937ce4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsdatePickerclient7d86b03a_26db26e4 === 'function') {
    await nuxt_plugin_integrationsdatePickerclient7d86b03a_26db26e4(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsdraggableclient3b95cae2_6894993a === 'function') {
    await nuxt_plugin_integrationsdraggableclient3b95cae2_6894993a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsembedsocialclientd4dd104a_2a9c49fb === 'function') {
    await nuxt_plugin_integrationsembedsocialclientd4dd104a_2a9c49fb(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsextendSDKclienta7d9c756_23f78f7d === 'function') {
    await nuxt_plugin_integrationsextendSDKclienta7d9c756_23f78f7d(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsgoogleBadgecliente7999782_3f94dc89 === 'function') {
    await nuxt_plugin_integrationsgoogleBadgecliente7999782_3f94dc89(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsgoogleMapsclient1464ad12_2cef735c === 'function') {
    await nuxt_plugin_integrationsgoogleMapsclient1464ad12_2cef735c(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsgoogleSurveyclient1ee509fa_066698a8 === 'function') {
    await nuxt_plugin_integrationsgoogleSurveyclient1ee509fa_066698a8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsgtagclientc1104c4c_3f6b0d6e === 'function') {
    await nuxt_plugin_integrationsgtagclientc1104c4c_3f6b0d6e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationshammerclient7a7038cd_567de410 === 'function') {
    await nuxt_plugin_integrationshammerclient7a7038cd_567de410(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationshlsclient0c77b7f8_25af5658 === 'function') {
    await nuxt_plugin_integrationshlsclient0c77b7f8_25af5658(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsimaskclient41ea1cac_8fe34d0a === 'function') {
    await nuxt_plugin_integrationsimaskclient41ea1cac_8fe34d0a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsiv3dclient1f9faac5_45d7db56 === 'function') {
    await nuxt_plugin_integrationsiv3dclient1f9faac5_45d7db56(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationslazyloadclient1f7825be_7a951f1e === 'function') {
    await nuxt_plugin_integrationslazyloadclient1f7825be_7a951f1e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsmicrosoftClarityclient143103e6_b04123a8 === 'function') {
    await nuxt_plugin_integrationsmicrosoftClarityclient143103e6_b04123a8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsmodernizrclient2d518cf7_6edf3971 === 'function') {
    await nuxt_plugin_integrationsmodernizrclient2d518cf7_6edf3971(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsnouisliderclient838a4a86_5e59d8e2 === 'function') {
    await nuxt_plugin_integrationsnouisliderclient838a4a86_5e59d8e2(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsperfectScrollbarclient399c97a0_70fe7b9e === 'function') {
    await nuxt_plugin_integrationsperfectScrollbarclient399c97a0_70fe7b9e(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationspopperclient3bbd309a_7b0ca5b6 === 'function') {
    await nuxt_plugin_integrationspopperclient3bbd309a_7b0ca5b6(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsrecaptchaclient3fbd89bc_243deb1f === 'function') {
    await nuxt_plugin_integrationsrecaptchaclient3fbd89bc_243deb1f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsrejoinerclient3c5ab3de_2e61c34f === 'function') {
    await nuxt_plugin_integrationsrejoinerclient3c5ab3de_2e61c34f(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationssocialSharingclient767477f8_247fc4a0 === 'function') {
    await nuxt_plugin_integrationssocialSharingclient767477f8_247fc4a0(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationssweetalertclient4d00fa73_43381d98 === 'function') {
    await nuxt_plugin_integrationssweetalertclient4d00fa73_43381d98(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsswiperclient28a1cfff_fb112354 === 'function') {
    await nuxt_plugin_integrationsswiperclient28a1cfff_fb112354(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationstabulatorclient473d2152_4241bf2a === 'function') {
    await nuxt_plugin_integrationstabulatorclient473d2152_4241bf2a(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationstippyclient3b1b93f9_d241e1e8 === 'function') {
    await nuxt_plugin_integrationstippyclient3b1b93f9_d241e1e8(app.context, inject)
  }

  if (process.client && typeof nuxt_plugin_integrationsuserlikeclient4bbb3aae_6b972ef6 === 'function') {
    await nuxt_plugin_integrationsuserlikeclient4bbb3aae_6b972ef6(app.context, inject)
  }

  // Lock enablePreview in context
  if (process.static && process.client) {
    app.context.enablePreview = function () {
      console.warn('You cannot call enablePreview() outside a plugin.')
    }
  }

  // Wait for async component to be resolved first
  await new Promise((resolve, reject) => {
    // Ignore 404s rather than blindly replacing URL in browser
    if (process.client) {
      const { route } = router.resolve(app.context.route.fullPath)
      if (!route.matched.length) {
        return resolve()
      }
    }
    router.replace(app.context.route.fullPath, resolve, (err) => {
      // https://github.com/vuejs/vue-router/blob/v3.4.3/src/util/errors.js
      if (!err._isRouter) return reject(err)
      if (err.type !== 2 /* NavigationFailureType.redirected */) return resolve()

      // navigated to a different route in router guard
      const unregister = router.afterEach(async (to, from) => {
        if (process.server && ssrContext && ssrContext.url) {
          ssrContext.url = to.fullPath
        }
        app.context.route = await getRouteData(to)
        app.context.params = to.params || {}
        app.context.query = to.query || {}
        unregister()
        resolve()
      })
    })
  })

  return {
    store,
    app,
    router
  }
}

export { createApp, NuxtError }

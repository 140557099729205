export default ($axios) => {
  const axiosWP = $axios.create({
    baseURL: process.env.wpAPIHost,
    port: 443
  })

  return {
    async search(query) {
      const data = await axiosWP.$get(`wp-json/wp/v2/search?${query}`)
      return data
    },

    async fetchPageById(id) {
      const data = await axiosWP.$get(`wp-json/wp/v2/pages/${id}&_embed`)
      return data
    },

    async fetchPageBySlug(slug) {
      const data = await axiosWP.$get(`wp-json/wp/v2/pages?slug=${slug}&_embed`)
      return data
    },

    async fetchPostById(id) {
      const data = await axiosWP.$get(`wp-json/wp/v2/posts/${id}&_embed`)
      return data
    },

    async fetchPostBySlug(slug) {
      const data = await axiosWP.$get(`wp-json/wp/v2/posts?slug=${slug}&_embed`)
      return data
    },

    async fetchPages(query = '') {
      const data = await axiosWP.$get(`wp-json/wp/v2/pages${query}`)
      return data
    },

    async fetchMenu(id) {
      const data = await axiosWP.$get(`wp-json/wp-api-menus/v2/menus/${id}`)
      return data
    },

    async fetchMenus() {
      const data = await axiosWP.$get('wp-json/wp-api-menus/v2/menus')
      return data
    }
  }
}

import Vue from 'vue'
import Router from 'vue-router'
import { normalizeURL, decode } from 'ufo'
import { interopDefault } from './utils'
import scrollBehavior from './router.scrollBehavior.js'

const _13d63512 = () => interopDefault(import('../../projects/gemstone/pages/account/index.vue' /* webpackChunkName: "pages/account/index" */))
const _8e7ed2ae = () => interopDefault(import('../../projects/gemstone/pages/all-gemstone-rings/index.vue' /* webpackChunkName: "pages/all-gemstone-rings/index" */))
const _54b6aee3 = () => interopDefault(import('../../projects/gemstone/pages/all-natural-gemstone-pairs/index.vue' /* webpackChunkName: "pages/all-natural-gemstone-pairs/index" */))
const _74ccc75c = () => interopDefault(import('../../projects/gemstone/pages/all-natural-gemstones/index.vue' /* webpackChunkName: "pages/all-natural-gemstones/index" */))
const _a065f20e = () => interopDefault(import('../../projects/gemstone/pages/auth/index.vue' /* webpackChunkName: "pages/auth/index" */))
const _86bda31e = () => interopDefault(import('../../projects/gemstone/pages/cart/index.vue' /* webpackChunkName: "pages/cart/index" */))
const _68194798 = () => interopDefault(import('../../projects/gemstone/pages/cart/index/index.vue' /* webpackChunkName: "pages/cart/index/index" */))
const _5e8110ae = () => interopDefault(import('../../projects/gemstone/pages/cart/index/payment-order.vue' /* webpackChunkName: "pages/cart/index/payment-order" */))
const _f66ee484 = () => interopDefault(import('../../projects/gemstone/pages/cart/index/secure-payment.vue' /* webpackChunkName: "pages/cart/index/secure-payment" */))
const _468e1b52 = () => interopDefault(import('../../projects/gemstone/pages/compare-list/index.vue' /* webpackChunkName: "pages/compare-list/index" */))
const _e7f59f08 = () => interopDefault(import('../../projects/gemstone/pages/contact-us/index.vue' /* webpackChunkName: "pages/contact-us/index" */))
const _77324f0d = () => interopDefault(import('../../projects/gemstone/pages/custom-design-gemstone-rings-jewelry/index.vue' /* webpackChunkName: "pages/custom-design-gemstone-rings-jewelry/index" */))
const _10c9da4d = () => interopDefault(import('../../projects/gemstone/pages/education/index.vue' /* webpackChunkName: "pages/education/index" */))
const _bcea99e0 = () => interopDefault(import('../../projects/gemstone/pages/education/index/index.vue' /* webpackChunkName: "pages/education/index/index" */))
const _0c89be5e = () => interopDefault(import('../../projects/gemstone/pages/education/index/_category/index.vue' /* webpackChunkName: "pages/education/index/_category/index" */))
const _14defb19 = () => interopDefault(import('../../projects/gemstone/pages/education/index/_category/_slug/index.vue' /* webpackChunkName: "pages/education/index/_category/_slug/index" */))
const _384eda3b = () => interopDefault(import('../../projects/gemstone/pages/extend-protection-plan/index.vue' /* webpackChunkName: "pages/extend-protection-plan/index" */))
const _db1ce388 = () => interopDefault(import('../../projects/gemstone/pages/favorites/index.vue' /* webpackChunkName: "pages/favorites/index" */))
const _55938ccc = () => interopDefault(import('../../projects/gemstone/pages/featured-designers/index.vue' /* webpackChunkName: "pages/featured-designers/index" */))
const _33fc51fc = () => interopDefault(import('../../projects/gemstone/pages/financing/index.vue' /* webpackChunkName: "pages/financing/index" */))
const _4a53d17c = () => interopDefault(import('../../projects/gemstone/pages/gemstone-bracelet-settings/index.vue' /* webpackChunkName: "pages/gemstone-bracelet-settings/index" */))
const _4764cfa5 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-bracelets/index.vue' /* webpackChunkName: "pages/gemstone-bracelets/index" */))
const _f87c9cc2 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-brooches/index.vue' /* webpackChunkName: "pages/gemstone-brooches/index" */))
const _f0e286fe = () => interopDefault(import('../../projects/gemstone/pages/gemstone-cufflinks/index.vue' /* webpackChunkName: "pages/gemstone-cufflinks/index" */))
const _3e828166 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-earring-settings/index.vue' /* webpackChunkName: "pages/gemstone-earring-settings/index" */))
const _1a1f9dc9 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-earrings/index.vue' /* webpackChunkName: "pages/gemstone-earrings/index" */))
const _6533f9e5 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-necklace-pendant-settings/index.vue' /* webpackChunkName: "pages/gemstone-necklace-pendant-settings/index" */))
const _4f9a107a = () => interopDefault(import('../../projects/gemstone/pages/gemstone-necklaces-pendants/index.vue' /* webpackChunkName: "pages/gemstone-necklaces-pendants/index" */))
const _dcee4acc = () => interopDefault(import('../../projects/gemstone/pages/gemstone-ring-settings/index.vue' /* webpackChunkName: "pages/gemstone-ring-settings/index" */))
const _7e5c6afd = () => interopDefault(import('../../projects/gemstone/pages/gemstone-rings/index.vue' /* webpackChunkName: "pages/gemstone-rings/index" */))
const _370664ae = () => interopDefault(import('../../projects/gemstone/pages/meet-our-team/index.vue' /* webpackChunkName: "pages/meet-our-team/index" */))
const _f6e36544 = () => interopDefault(import('../../projects/gemstone/pages/mens-gemstone-ring-settings/index.vue' /* webpackChunkName: "pages/mens-gemstone-ring-settings/index" */))
const _37d2e1c1 = () => interopDefault(import('../../projects/gemstone/pages/mens-gemstone-rings/index.vue' /* webpackChunkName: "pages/mens-gemstone-rings/index" */))
const _0a7b5a7a = () => interopDefault(import('../../projects/gemstone/pages/michael-arnstein/index.vue' /* webpackChunkName: "pages/michael-arnstein/index" */))
const _bc9a2192 = () => interopDefault(import('../../projects/gemstone/pages/natural-gemstone-pairs/index.vue' /* webpackChunkName: "pages/natural-gemstone-pairs/index" */))
const _92b91104 = () => interopDefault(import('../../projects/gemstone/pages/natural-gemstones/index.vue' /* webpackChunkName: "pages/natural-gemstones/index" */))
const _410850ae = () => interopDefault(import('../../projects/gemstone/pages/showroom/index.vue' /* webpackChunkName: "pages/showroom/index" */))
const _2065fc15 = () => interopDefault(import('../../projects/gemstone/pages/account/address-book/index.vue' /* webpackChunkName: "pages/account/address-book/index" */))
const _3a1c884a = () => interopDefault(import('../../projects/gemstone/pages/account/change-password/index.vue' /* webpackChunkName: "pages/account/change-password/index" */))
const _2df7c6ab = () => interopDefault(import('../../projects/gemstone/pages/account/notifications/index.vue' /* webpackChunkName: "pages/account/notifications/index" */))
const _1fa45fd0 = () => interopDefault(import('../../projects/gemstone/pages/account/orders/index.vue' /* webpackChunkName: "pages/account/orders/index" */))
const _3374d6e8 = () => interopDefault(import('../../projects/gemstone/pages/account/personal-details/index.vue' /* webpackChunkName: "pages/account/personal-details/index" */))
const _24a553a7 = () => interopDefault(import('../../projects/gemstone/pages/account/preview-request-submissions/index.vue' /* webpackChunkName: "pages/account/preview-request-submissions/index" */))
const _7586d91d = () => interopDefault(import('../../projects/gemstone/pages/account/recommendations/index.vue' /* webpackChunkName: "pages/account/recommendations/index" */))
const _3e0bbd8c = () => interopDefault(import('../../projects/gemstone/pages/account/reset-password/index.vue' /* webpackChunkName: "pages/account/reset-password/index" */))
const _08214046 = () => interopDefault(import('../../projects/gemstone/pages/cart/order-summary.vue' /* webpackChunkName: "pages/cart/order-summary" */))
const _dc625842 = () => interopDefault(import('../../projects/gemstone/pages/showroom/preview.vue' /* webpackChunkName: "pages/showroom/preview" */))
const _6a7eb722 = () => interopDefault(import('../../projects/gemstone/pages/showroom/preview/index.vue' /* webpackChunkName: "pages/showroom/preview/index" */))
const _68f57fd3 = () => interopDefault(import('../../projects/gemstone/pages/showroom/preview/success.vue' /* webpackChunkName: "pages/showroom/preview/success" */))
const _4cada474 = () => interopDefault(import('../../projects/gemstone/pages/index.vue' /* webpackChunkName: "pages/index" */))
const _e996e39a = () => interopDefault(import('../../projects/gemstone/pages/account/reset-password/_token/index.vue' /* webpackChunkName: "pages/account/reset-password/_token/index" */))
const _0dfcb586 = () => interopDefault(import('../../projects/gemstone/pages/account/set-password/_token/index.vue' /* webpackChunkName: "pages/account/set-password/_token/index" */))
const _814f2df0 = () => interopDefault(import('../../projects/gemstone/pages/design-your-own/review/_id/index.vue' /* webpackChunkName: "pages/design-your-own/review/_id/index" */))
const _bea75d96 = () => interopDefault(import('../../projects/gemstone/pages/featured-designers/_name/index.vue' /* webpackChunkName: "pages/featured-designers/_name/index" */))
const _3e6dc12d = () => interopDefault(import('../../projects/gemstone/pages/gemstone-bracelet-settings/_id/index.vue' /* webpackChunkName: "pages/gemstone-bracelet-settings/_id/index" */))
const _4b58dfe0 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-bracelets/_id/index.vue' /* webpackChunkName: "pages/gemstone-bracelets/_id/index" */))
const _0dca35ec = () => interopDefault(import('../../projects/gemstone/pages/gemstone-brooches/_id/index.vue' /* webpackChunkName: "pages/gemstone-brooches/_id/index" */))
const _3078a228 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-cufflinks/_id/index.vue' /* webpackChunkName: "pages/gemstone-cufflinks/_id/index" */))
const _785f0d5e = () => interopDefault(import('../../projects/gemstone/pages/gemstone-earring-settings/_id/index.vue' /* webpackChunkName: "pages/gemstone-earring-settings/_id/index" */))
const _18f2ac34 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-earrings/_id/index.vue' /* webpackChunkName: "pages/gemstone-earrings/_id/index" */))
const _67f8cb60 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-necklace-pendant-settings/_id/index.vue' /* webpackChunkName: "pages/gemstone-necklace-pendant-settings/_id/index" */))
const _ed170da4 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-necklaces-pendants/_id/index.vue' /* webpackChunkName: "pages/gemstone-necklaces-pendants/_id/index" */))
const _0cdf1885 = () => interopDefault(import('../../projects/gemstone/pages/gemstone-ring-settings/_id/index.vue' /* webpackChunkName: "pages/gemstone-ring-settings/_id/index" */))
const _46a58949 = () => interopDefault(import('../../projects/gemstone/pages/mens-gemstone-ring-settings/_id/index.vue' /* webpackChunkName: "pages/mens-gemstone-ring-settings/_id/index" */))
const _3a1997a8 = () => interopDefault(import('../../projects/gemstone/pages/mens-gemstone-rings/_id/index.vue' /* webpackChunkName: "pages/mens-gemstone-rings/_id/index" */))
const _59dfc9a0 = () => interopDefault(import('../../projects/gemstone/pages/featured-designers/_name/_id/index.vue' /* webpackChunkName: "pages/featured-designers/_name/_id/index" */))
const _298a9389 = () => interopDefault(import('../../projects/gemstone/pages/_dynamicListing/index.vue' /* webpackChunkName: "pages/_dynamicListing/index" */))
const _2b267c18 = () => interopDefault(import('../../projects/gemstone/pages/_dynamicListing/_id/index.vue' /* webpackChunkName: "pages/_dynamicListing/_id/index" */))

const emptyFn = () => {}

Vue.use(Router)

export const routerOptions = {
  mode: 'history',
  base: '/',
  linkActiveClass: 'nuxt-link-active',
  linkExactActiveClass: 'nuxt-link-exact-active',
  scrollBehavior,

  routes: [{
    path: "/account/",
    component: _13d63512,
    pathToRegexpOptions: {"strict":true},
    name: "account___en"
  }, {
    path: "/all-gemstone-rings/",
    component: _8e7ed2ae,
    pathToRegexpOptions: {"strict":true},
    name: "all-gemstone-rings___en"
  }, {
    path: "/all-natural-gemstone-pairs/",
    component: _54b6aee3,
    pathToRegexpOptions: {"strict":true},
    name: "all-natural-gemstone-pairs___en"
  }, {
    path: "/all-natural-gemstones/",
    component: _74ccc75c,
    pathToRegexpOptions: {"strict":true},
    name: "all-natural-gemstones___en"
  }, {
    path: "/auth/",
    component: _a065f20e,
    pathToRegexpOptions: {"strict":true},
    name: "auth___en"
  }, {
    path: "/cart/",
    component: _86bda31e,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _68194798,
      pathToRegexpOptions: {"strict":true},
      name: "cart-index___en"
    }, {
      path: "payment-order/",
      component: _5e8110ae,
      pathToRegexpOptions: {"strict":true},
      name: "cart-index-payment-order___en"
    }, {
      path: "secure-payment/",
      component: _f66ee484,
      pathToRegexpOptions: {"strict":true},
      name: "cart-index-secure-payment___en"
    }]
  }, {
    path: "/compare-list/",
    component: _468e1b52,
    pathToRegexpOptions: {"strict":true},
    name: "compare-list___en"
  }, {
    path: "/contact-us/",
    component: _e7f59f08,
    pathToRegexpOptions: {"strict":true},
    name: "contact-us___en"
  }, {
    path: "/custom-design-gemstone-rings-jewelry/",
    component: _77324f0d,
    pathToRegexpOptions: {"strict":true},
    name: "custom-design-gemstone-rings-jewelry___en"
  }, {
    path: "/education/",
    component: _10c9da4d,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _bcea99e0,
      pathToRegexpOptions: {"strict":true},
      name: "education-index___en"
    }, {
      path: ":category/",
      component: _0c89be5e,
      pathToRegexpOptions: {"strict":true},
      name: "education-index-category___en"
    }, {
      path: ":category/:slug/",
      component: _14defb19,
      pathToRegexpOptions: {"strict":true},
      name: "education-index-category-slug___en"
    }]
  }, {
    path: "/extend-protection-plan/",
    component: _384eda3b,
    pathToRegexpOptions: {"strict":true},
    name: "extend-protection-plan___en"
  }, {
    path: "/favorites/",
    component: _db1ce388,
    pathToRegexpOptions: {"strict":true},
    name: "favorites___en"
  }, {
    path: "/featured-designers/",
    component: _55938ccc,
    pathToRegexpOptions: {"strict":true},
    name: "featured-designers___en"
  }, {
    path: "/financing/",
    component: _33fc51fc,
    pathToRegexpOptions: {"strict":true},
    name: "financing___en"
  }, {
    path: "/gemstone-bracelet-settings/",
    component: _4a53d17c,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-bracelet-settings___en"
  }, {
    path: "/gemstone-bracelets/",
    component: _4764cfa5,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-bracelets___en"
  }, {
    path: "/gemstone-brooches/",
    component: _f87c9cc2,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-brooches___en"
  }, {
    path: "/gemstone-cufflinks/",
    component: _f0e286fe,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-cufflinks___en"
  }, {
    path: "/gemstone-earring-settings/",
    component: _3e828166,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-earring-settings___en"
  }, {
    path: "/gemstone-earrings/",
    component: _1a1f9dc9,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-earrings___en"
  }, {
    path: "/gemstone-necklace-pendant-settings/",
    component: _6533f9e5,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-necklace-pendant-settings___en"
  }, {
    path: "/gemstone-necklaces-pendants/",
    component: _4f9a107a,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-necklaces-pendants___en"
  }, {
    path: "/gemstone-ring-settings/",
    component: _dcee4acc,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-ring-settings___en"
  }, {
    path: "/gemstone-rings/",
    component: _7e5c6afd,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-rings___en"
  }, {
    path: "/meet-our-team/",
    component: _370664ae,
    pathToRegexpOptions: {"strict":true},
    name: "meet-our-team___en"
  }, {
    path: "/mens-gemstone-ring-settings/",
    component: _f6e36544,
    pathToRegexpOptions: {"strict":true},
    name: "mens-gemstone-ring-settings___en"
  }, {
    path: "/mens-gemstone-rings/",
    component: _37d2e1c1,
    pathToRegexpOptions: {"strict":true},
    name: "mens-gemstone-rings___en"
  }, {
    path: "/michael-arnstein/",
    component: _0a7b5a7a,
    pathToRegexpOptions: {"strict":true},
    name: "michael-arnstein___en"
  }, {
    path: "/natural-gemstone-pairs/",
    component: _bc9a2192,
    pathToRegexpOptions: {"strict":true},
    name: "natural-gemstone-pairs___en"
  }, {
    path: "/natural-gemstones/",
    component: _92b91104,
    pathToRegexpOptions: {"strict":true},
    name: "natural-gemstones___en"
  }, {
    path: "/showroom/",
    component: _410850ae,
    pathToRegexpOptions: {"strict":true},
    name: "showroom___en"
  }, {
    path: "/account/address-book/",
    component: _2065fc15,
    pathToRegexpOptions: {"strict":true},
    name: "account-address-book___en"
  }, {
    path: "/account/change-password/",
    component: _3a1c884a,
    pathToRegexpOptions: {"strict":true},
    name: "account-change-password___en"
  }, {
    path: "/account/notifications/",
    component: _2df7c6ab,
    pathToRegexpOptions: {"strict":true},
    name: "account-notifications___en"
  }, {
    path: "/account/orders/",
    component: _1fa45fd0,
    pathToRegexpOptions: {"strict":true},
    name: "account-orders___en"
  }, {
    path: "/account/personal-details/",
    component: _3374d6e8,
    pathToRegexpOptions: {"strict":true},
    name: "account-personal-details___en"
  }, {
    path: "/account/preview-request-submissions/",
    component: _24a553a7,
    pathToRegexpOptions: {"strict":true},
    name: "account-preview-request-submissions___en"
  }, {
    path: "/account/recommendations/",
    component: _7586d91d,
    pathToRegexpOptions: {"strict":true},
    name: "account-recommendations___en"
  }, {
    path: "/account/reset-password/",
    component: _3e0bbd8c,
    pathToRegexpOptions: {"strict":true},
    name: "account-reset-password___en"
  }, {
    path: "/cart/order-summary/",
    component: _08214046,
    pathToRegexpOptions: {"strict":true},
    name: "cart-order-summary___en"
  }, {
    path: "/showroom/preview/",
    component: _dc625842,
    pathToRegexpOptions: {"strict":true},
    children: [{
      path: "",
      component: _6a7eb722,
      pathToRegexpOptions: {"strict":true},
      name: "showroom-preview___en"
    }, {
      path: "success/",
      component: _68f57fd3,
      pathToRegexpOptions: {"strict":true},
      name: "showroom-preview-success___en"
    }]
  }, {
    path: "/",
    component: _4cada474,
    pathToRegexpOptions: {"strict":true},
    name: "index___en"
  }, {
    path: "/account/reset-password/:token/",
    component: _e996e39a,
    pathToRegexpOptions: {"strict":true},
    name: "account-reset-password-token___en"
  }, {
    path: "/account/set-password/:token/",
    component: _0dfcb586,
    pathToRegexpOptions: {"strict":true},
    name: "account-set-password-token___en"
  }, {
    path: "/design-your-own/review/:id/",
    component: _814f2df0,
    pathToRegexpOptions: {"strict":true},
    name: "design-your-own-review-id___en"
  }, {
    path: "/featured-designers/:name/",
    component: _bea75d96,
    pathToRegexpOptions: {"strict":true},
    name: "featured-designers-name___en"
  }, {
    path: "/gemstone-bracelet-settings/:id/",
    component: _3e6dc12d,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-bracelet-settings-id___en"
  }, {
    path: "/gemstone-bracelets/:id/",
    component: _4b58dfe0,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-bracelets-id___en"
  }, {
    path: "/gemstone-brooches/:id/",
    component: _0dca35ec,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-brooches-id___en"
  }, {
    path: "/gemstone-cufflinks/:id/",
    component: _3078a228,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-cufflinks-id___en"
  }, {
    path: "/gemstone-earring-settings/:id/",
    component: _785f0d5e,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-earring-settings-id___en"
  }, {
    path: "/gemstone-earrings/:id/",
    component: _18f2ac34,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-earrings-id___en"
  }, {
    path: "/gemstone-necklace-pendant-settings/:id/",
    component: _67f8cb60,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-necklace-pendant-settings-id___en"
  }, {
    path: "/gemstone-necklaces-pendants/:id/",
    component: _ed170da4,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-necklaces-pendants-id___en"
  }, {
    path: "/gemstone-ring-settings/:id/",
    component: _0cdf1885,
    pathToRegexpOptions: {"strict":true},
    name: "gemstone-ring-settings-id___en"
  }, {
    path: "/mens-gemstone-ring-settings/:id/",
    component: _46a58949,
    pathToRegexpOptions: {"strict":true},
    name: "mens-gemstone-ring-settings-id___en"
  }, {
    path: "/mens-gemstone-rings/:id/",
    component: _3a1997a8,
    pathToRegexpOptions: {"strict":true},
    name: "mens-gemstone-rings-id___en"
  }, {
    path: "/featured-designers/:name?/:id/",
    component: _59dfc9a0,
    pathToRegexpOptions: {"strict":true},
    name: "featured-designers-name-id___en"
  }, {
    path: "/:dynamicListing/",
    component: _298a9389,
    pathToRegexpOptions: {"strict":true},
    name: "dynamicListing___en"
  }, {
    path: "/:dynamicListing/:id/",
    component: _2b267c18,
    pathToRegexpOptions: {"strict":true},
    name: "dynamicListing-id___en"
  }],

  fallback: false
}

export function createRouter (ssrContext, config) {
  const base = (config._app && config._app.basePath) || routerOptions.base
  const router = new Router({ ...routerOptions, base  })

  // TODO: remove in Nuxt 3
  const originalPush = router.push
  router.push = function push (location, onComplete = emptyFn, onAbort) {
    return originalPush.call(this, location, onComplete, onAbort)
  }

  const resolve = router.resolve.bind(router)
  router.resolve = (to, current, append) => {
    if (typeof to === 'string') {
      to = normalizeURL(to)
    }
    return resolve(to, current, append)
  }

  return router
}

import OnDemand from '~~/utils/helpers/onDemand'

/* global IMask */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/imask.min.js?v=6.0.7'
  })

  inject('imask', {
    insert: (el, options, callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          resolve(new IMask(el, options))
          if (callback) callback()
        })
      })
  })
}

import OnDemand from '~~/utils/helpers/onDemand'

/* global Modernizr */
export default (ctx, inject) => {
  const loader = new OnDemand({
    script:
      'https://service.thenaturalsapphirecompany.com/cdn/modernizr-custom.js'
  })

  inject('modernizr', {
    insert: (callback) =>
      new Promise((resolve, reject) => {
        loader.load(() => {
          const store = ctx.store
          Modernizr.on('webp', function (result) {
            if (result) {
              // supported
              store.dispatch('app/setWebpSupport', true)
            } else {
              // not-supported
              store.dispatch('app/setWebpSupport', false)
            }
          })

          resolve()
          if (callback) callback()
        })
      })
  })
}
